import React from "react";
import "./HeaderLower.css";
function HeaderLower() {
  return (
    <div className="header_lower">
      Rest test BEAUTYREST BLACK and Enter to Win a $5,000 Levin/Ashley/JV Schultz Shopping Spree!
      {/* Rest Test BEAUTYREST BLACK and Enter to Win a $5,000 Levin Shopping Spree! */}
    </div>
  );
}
export default HeaderLower;
